import { CSSObject } from 'styled-components';

export const options = [
  {
    value:
      window?.config?.REACT_APP_PARTNER_TEST_ID ??
      process.env.REACT_APP_PARTNER_TEST_ID,
    label: 'Parsley Baseline Panel',
  },
];

export const selectStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => ({
    marginRight: '12px',
  }),
  placeholder: () => ({
    fontFamily: 'Lato',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.64px',
  }),
  singleValue: () => ({
    fontFamily: 'Lato',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.64px',
  }),
  menuList: () => ({
    fontFamily: 'Lato',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.64px',
  }),
  valueContainer: (provided: CSSObject) => ({
    ...provided,
    padding: '12px 16px',
  }),
  control: (provided: CSSObject) => ({
    ...provided,
    border: '1px solid #B9C8D3',
  }),
};
